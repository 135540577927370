import '../static/style/sectionStyle.css'
import { useIntersection } from '../customHooks/UseIntersection'
import { useEffect } from 'react'
import emailIcon from '../static/images/email.png'
import githubIcon from '../static/images/github.png'
import linkedInIcon from '../static/images/linkedin.png'
import resume from '../static/images/JamesLotruglio_Resume.pdf'
import resumeIcon from '../static/images/resumeIcon.png'

export const Contact = ({ secFourRef, detectScroll }) => {
  const visible = useIntersection(secFourRef)

  useEffect(() => {
    if (visible) detectScroll(secFourRef)

  }, [visible, detectScroll, secFourRef])

  return (
    <div id="sec-4" ref={secFourRef} className="item">
      <h1 className='contact-title'>Contact</h1>
      <div className="contact-div">
        <div className="email-div">
        <img src={emailIcon} alt="" className='email-icon' />
        <a href='mailto:jameslotruglio@gmail.com' className='contact-p'>jameslotruglio@gmail.com</a>
        </div>
        <div className="email-div">
        <img src={githubIcon} alt="" className='email-icon' />
        <a href='https://github.com/jlotrug' target="_blank" rel="noreferrer" className='contact-p'>jlotrug</a>
        </div>
        <div className="email-div">
        <img src={linkedInIcon} alt="" className='email-icon' />
        <a href='https://linkedin.com/in/jim-lotruglio' target="_blank" rel="noreferrer" className='contact-p'>jim-lotruglio</a>
        </div>
        <div className="email-div">
        <img src={resumeIcon} alt="" className='email-icon' />
        <a className="resume-link" href={resume} rel="noreferrer" target="_blank">
                View My Resume
                </a>
        </div>
          
      </div>
    </div>
  )
}